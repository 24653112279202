.menu-box {
  border: solid 1px #8397a8;
  background-color: #8d9dab;
  padding: 0px 10px;
  display: flex;
  color: #00162a;
}

.menu-box a{
  font-size: 1.1em;
  color: #00162a;
}
