/* Estilo personalizado para a área do motorista*/
   /* Terms*/
.terms input{
  margin-right:10px;
}

.terms svg{
  font-size: 0.9em;

}

.terms-link {
  text-decoration: underline;
  cursor: pointer;
  color: #007bff;
}
/************************************************/
    /*sucesso*/
.sucesso-info {
  padding: 10px 0;
  font-size: 1.1em;
  line-height: 1.5rem;
}

.sucesso-dados-prefeitura {
  padding: 10px 0;
  font-size: 1.1em;
  line-height: 1.5rem;
  font-weight: bold;
  white-space: pre-line;
}

.texto-parabens {
  color: green;
  font-size: 1.3em;
  line-height: 2rem;
}

.label-taxi {
  margin-top:15px;
  font-weight: bold;
}
/************************/
#corrigir{
  padding-top: 10px;
}
.error {
  display: block;
  padding-top: 5px;
  color:red;
  font-size: 0.9em;
}
.caixa-informacoes-taxista {
  border: solid 1px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 5px;
  background-color: rgb(253,253,253);
  color: #3a3a3a;
  font-weight: bold;
}
.show-password {
  position:absolute;
  right:0;
  margin-top:50px;
  margin-right: 20px;
  cursor: pointer;
  z-index: 10000000;
  padding: 5px 10px;
}
.complementar-dados-itens {
  margin-bottom: 5px;;
}

.content-fluid-taxirio{
    padding-left: 0!important;
    padding-right: 0!important;
}
.navbar-orgao p {
    color: #fff;
    font-size: 18px;
}

.taxirio-title {
    border: 1px solid yellow;
}

.container-fluid-taxirio {
  margin-top: 0px;
  padding:0;
}

.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  bottom:0;
}

/* HOME */
 /* NAVBAR */
.cabecalho {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.home-cabecalho-imagem{
  margin-top: 15px;
}

.logo-cidades-home {
  width: 80px;
}

.titulo {
  color: #eee;
  font-weight: bold;
  font-size: 1.2em;
  justify-content: center;

}
/* Fim navbar*/

/*Home - Conteúdo*/
.home-container {
  padding: 30px;
}
/*Fim Home-conteúdo*/

/* Default */


.cabecalho-default {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap!important;
  padding: 0px;
}
.default-cabecalho-imagem{
  margin-top: 15px;
  margin-left: 15px;
}

.logo-cidades-home {
  width: 70px;
}

.titulo-default {
  color: #eee;
  font-weight: bold;
  font-size: 1.2em;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-right:85px;
}

.default-cabecalho-nome-cidade {
  color: white;
  align-self: baseline;  
  font-weight: bold;
}

.navbar-taxirio-default {
  flex-direction: row;
}
.sair {
  color: white;
  cursor: pointer;
}
.base-cabecalho{
  border-top: 1px solid #fffeff;
  margin: 10px 5px;
  padding-left: 10px;
  padding-top: 2px;
  justify-content: space-between;
}

/* FIM Default  */


/*
.navbar-taxirio {
    background-color:#02162a !important;
    height: 100px;
    border-bottom: 4px solid #ffc138;
    height: 125px;
    margin-bottom: 0px;
    padding-top: 0;
}*/

.legenda {
  font-size: 1.1em;
}

.navbar-brand-taxirio{
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.copyright {
  padding-left: 8px;
}
/* */
body{
	font-family: 'Calibri', Arial, 'Opens Sans', 'Roboto', sans-serif !important;
  background-color:#fffeff;
  line-height: 1.42857143;
  padding-bottom: 50px;
}

/*Fonts */
label, .ui-widget {
	font-family: 'Calibri', Arial,  'Opens Sans', 'Roboto', sans-serif;
}


/* Buttons */
.btn-taxirio-amarelo{
	background: none !important;
	background-color: #ffcd5e !important;
	border-color: #ffcd5e !important;
	border-radius:4px !important;
	color: #002638 !important;
	font-weight:bold !important;
	-moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
	
}

.btn-taxirio-amarelo:hover, .btn-taxirio-amareloactive, .btn-taxirio-amarelo:focus{
	background-color: #ffc138 !important;
	border-color: #ffc138 !important;	
}

.btn-taxirio-azul{
	background-color: #002638 !important;
	border-color: #002638 !important;
	border-radius:4px !important;
	color: #ffcd5e !important;
	font-weight:bold !important;
}

.btn-taxirio-azul:hover, .btn-taxirio-azul:active, .btn-taxirio-azul:focus{
	background-color: #001722 !important;
	border-color: #001722 !important;
}

.btn-small{
    padding: 0px !important;
}


/*inputs*/
input{
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

/* Helper Classes */

.text-taxirio-amarelo{
	color:#ffcd5e;
}

.text-taxirio-azul{
	color: #002638;
}

.datalist-noborder .ui-widget-content {
	border: none;
}
			
.semBorda tr, .semBorda td {
	border: none !important;
	-moz-border-radius: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.ui-selectonemenu .ui-selectonemenu-trigger{
	width: auto !important;
}	
	

/* Primefaces Customize */

.ui-inputfield, .ui-widget-content .ui-inputfield, .ui-widget-header .ui-inputfield {
	-moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
	font-family: 'Calibri', Arial, 'Opens Sans', 'Roboto', sans-serif;
}

.ui-state-hover{
	-moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;	
}

/* LAYOUT DA PAGINA */

/* Cabeçalho */
.navbar-taxirio{
	background-color: #02162a;
	height:100px;
	border-bottom: 4px solid #ffc138;
	height:125px;
  margin-bottom:0px;
  padding:0px;
}

.navbar-orgao{
	/*width: 300px;*/
    color: #fff;
    float: left;
    /* padding-top: 20px; */
    margin-top: 14px;
    margin-left: 15px;
    font-size: 18px;
    border-left: 1px solid #fffeff;
    padding-left: 15px;
}

.navbar-orgao p{
	margin:0px;
}

.navbar-dados-usuario{
    color: #fff;
    border-top: 1px solid #fffeff;
    margin-top: 15px;
    padding-top: 8px;
    font-size: 16px;
}

.navbar-prefeitura{
    height: 50px;
    padding: 5px 15px;
    font-size: 18px;
    line-height: 20px;
}

.navbar-taxi-menu {
	background-color:#8d9dab;
	color: #00162a;
	font-size:18px;
	margin-bottom:0px;
}

.navbar-menu a :hover, .navbar-menu a:active, .navbar-menu a:focus{
	text-decoration: underline;
}

.navbar-left{
	float:left
}

.navbar-right{
	float:right;
}

/*breadcrumb*/
.navbar-breadcrumb {
/*	max-height:20px;*/
	padding:0px;
	margin:0px;
	background-color: #f3f3f3;
}

.navbar-breadcrumb .breadcrumb{
	background:none;
    padding: 10px 15px;
    font-size:12px;
}


/* Rodapé */
.navbar-rodape {
	color: white;
	background-color: #1d2127 !important;
	border-radius: 0px;
  padding: 0 10px 0 0;
  height: 55px;
}

.navbar-rodape nav {
  padding: 0px 0px 7px 0;
}
.navbar-rodape p {
	margin-bottom: 0;
	font-size: 11px;
}

.social-media {
	margin-top: 15px;
}

.social-media a{
	color:#efefef;
}

.social-media a:hover{
	color:#c1c1c1;
}


.passageiroTaxiRioConfirmado{
	color:#27ae60;	
}

.passageiroTaxiRioPendente{
	color:#e67e22;
}

.ui-icon { text-indent: 0; }

/*header*/
/*.navbar {margin-bottom:0px;min-height:0px;}
.navbar-brand {height:65px;padding:10px;}
.navbar-static-top, .navbar-fixed-top{background-color: #024467;background-image: linear-gradient(to bottom, #01324b, #05527e);}*/

/*header-botao-mobile
.navbar-inverse .navbar-toggle{border-color:#fff}
.navbar-inverse .navbar-toggle:hover {background-color:#fff}
.navbar-toggle{}*/

/*menu
.navbar-default .navbar-text, .navbar-inverse .navbar-text{color:#fff}
.navbar-inverse .navbar-nav>li>a {color: #fff;}
.navbar-inverse .navbar-nav>li>a:hover{color:#e7e7e7}*/


/*footer
.navbar-fixed-bottom{border-top: 3px solid #313030;background-color:#3e3e3e;color:#fff;}
*/

.content{margin-top:25px;margin-bottom:80px;}


/*FORMULARIO

.form-control {
padding: 7px 9px 8px 9px;
font-size: 14px;
border-color: #ddd;
box-shadow: none;
border-radius: 0;
}

.form-horizontal .control-label {text-align: left;}
.form-horizontal .control-label, .form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {margin-bottom: 5px;}
.form-control + .form-control, .input-group + .input-group {margin-top: 20px;}

.input-group-addon {
	font-size: 12px;
	border-color: #ddd;
	background-color: #fafafa;
	border-radius: 4px;
}

.checker {
position: relative;
margin-right: 6px;
margin-top: -3px;
display: inline-block;
cursor: pointer;
vertical-align: middle;
}
.checker, .checker span, .checker input {width: 18px;height: 18px;}
.choice, .choice span, .choice input {width: 18px;height: 18px;}
*/


.calendario input{
	width:60%;	
}


/*PANEL*/
.panel {border-radius: 2px;margin-bottom: 35px;box-shadow: none;}
.panel-black {border-color: #313030;}

.panel-black>.panel-heading {
color: #fff;
background-color: #313030;
border-color: #313030;
}

.panel .table-bordered,
.panel .table-responsive > .table-bordered {
  border: 0;
}

.panel .panel-body .table-bordered {
  border: 1px solid #ddd;
}

.panel > .table + .panel-body, 
.panel > .table-responsive + .panel-body {
border-top: 1px solid #DDD;
}


.panel .table:first-child,
.panel .table-responsive:first-child > .table:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 0;
}
.panel .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 0;
}
.panel .table:last-child,
.panel .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.panel .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 0;
}
.panel .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 0;
}

.panel .table-bordered > thead > tr > th:first-child,
.panel .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel .table-bordered > tbody > tr > th:first-child,
.panel .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel .table-bordered > tfoot > tr > th:first-child,
.panel .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel .table-bordered > thead > tr > td:first-child,
.panel .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel .table-bordered > tbody > tr > td:first-child,
.panel .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel .table-bordered > tfoot > tr > td:first-child,
.panel .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.panel .table-bordered > thead > tr > th:last-child,
.panel .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel .table-bordered > tbody > tr > th:last-child,
.panel .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel .table-bordered > tfoot > tr > th:last-child,
.panel .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel .table-bordered > thead > tr > td:last-child,
.panel .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel .table-bordered > tbody > tr > td:last-child,
.panel .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel .table-bordered > tfoot > tr > td:last-child,
.panel .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.panel .table-bordered > thead > tr:first-child > td,
.panel .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel .table-bordered > tbody > tr:first-child > td,
.panel .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel .table-bordered > thead > tr:first-child > th,
.panel .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel .table-bordered > tbody > tr:first-child > th,
.panel .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}
.panel .table-bordered > tbody > tr:last-child > td,
.panel .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel .table-bordered > tfoot > tr:last-child > td,
.panel .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel .table-bordered > tbody > tr:last-child > th,
.panel .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel .table-bordered > tfoot > tr:last-child > th,
.panel .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel .table-bordered.dataTable {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.panel-body .table-bordered {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

/*TABLES*/

.table {
  margin-bottom: 0;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  outline: 0;
}

.table > thead {
	background-color:#3071a9;
	color:#fff;	
}

.table > thead > tr > th, 
.table > tbody > tr > th, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > tbody > tr > td, 
.table > tfoot > tr > td {
  vertical-align: middle;
  padding: 10px 12px;
}

.table-condensed > thead > tr > th, 
.table-condensed > tbody > tr > th, 
.table-condensed > tfoot > tr > th, 
.table-condensed > thead > tr > td, 
.table-condensed > tbody > tr > td, 
.table-condensed > tfoot > tr > td {
  padding: 8px 10px;
}

.table > thead > tr > td.danger, 
.table > tbody > tr > td.danger, 
.table > tfoot > tr > td.danger, 
.table > thead > tr > th.danger, 
.table > tbody > tr > th.danger, 
.table > tfoot > tr > th.danger, 
.table > thead > tr.danger > td, 
.table > tbody > tr.danger > td, 
.table > tfoot > tr.danger > td, 
.table > thead > tr.danger > th, 
.table > tbody > tr.danger > th, 
.table > tfoot > tr.danger > th {
  background-color: #FDF5F5;
}

.table > thead > tr > td.success, 
.table > tbody > tr > td.success, 
.table > tfoot > tr > td.success, 
.table > thead > tr > th.success, 
.table > tbody > tr > th.success, 
.table > tfoot > tr > th.success, 
.table > thead > tr.success > td, 
.table > tbody > tr.success > td, 
.table > tfoot > tr.success > td, 
.table > thead > tr.success > th, 
.table > tbody > tr.success > th, 
.table > tfoot > tr.success > th {
  background-color: #F5FAF4;
}

.table > thead > tr > td.warning, 
.table > tbody > tr > td.warning, 
.table > tfoot > tr > td.warning, 
.table > thead > tr > th.warning, 
.table > tbody > tr > th.warning, 
.table > tfoot > tr > th.warning, 
.table > thead > tr.warning > td, 
.table > tbody > tr.warning > td, 
.table > tfoot > tr.warning > td, 
.table > thead > tr.warning > th, 
.table > tbody > tr.warning > th, 
.table > tfoot > tr.warning > th {
  background-color: #FFFDF0;
}

.table > thead > tr > td.info, 
.table > tbody > tr > td.info, 
.table > tfoot > tr > td.info, 
.table > thead > tr > th.info, 
.table > tbody > tr > th.info, 
.table > tfoot > tr > th.info, 
.table > thead > tr.info > td, 
.table > tbody > tr.info > td, 
.table > tfoot > tr.info > td, 
.table > thead > tr.info > th, 
.table > tbody > tr.info > th, 
.table > tfoot > tr.info > th {
  background-color: #F5FBFD;
}

.table > thead > tr > td.active, 
.table > tbody > tr > td.active, 
.table > tfoot > tr > td.active, 
.table > thead > tr > th.active, 
.table > tbody > tr > th.active, 
.table > tfoot > tr > th.active, 
.table > thead > tr.active > td, 
.table > tbody > tr.active > td, 
.table > tfoot > tr.active > td, 
.table > thead > tr.active > th, 
.table > tbody > tr.active > th, 
.table > tfoot > tr.active > th {
  background-color: #fafafa;
}

.table-striped > tbody > tr:nth-child(odd) > td, 
.table-striped > tbody > tr:nth-child(odd) > th,
.table-hover > tbody > tr:hover > td, 
.table-hover > tbody > tr:hover > th {
  background-color: #fcfcfc;
}


.panel .table-footer {
border: 0;
border-top: 1px solid #ddd;
}
.table-footer {
padding: 12px;
background-color: #fcfcfc;
border: 1px solid #ddd;
}

.table-footer .table-actions {
float: left;
}
.pagination {
margin: 0;
border-radius: 0;
font-size: 0;
}

.table-footer .table-actions label {
  margin: 3px 12px 0 0;
  display: inline-block;
  font-size: 12px;
}
.table-footer .table-actions {
  float: left;
}
.table-footer .pagination {
  float: left;
  margin-top: 2px;
}
.table-footer {
  padding: 12px;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
}
.panel .table-footer {
  border: 0;
  border-top: 1px solid #ddd;
}

/*PAGINATION*/

.pagination {
  margin: 0;
  border-radius: 0;
  font-size: 0;
}
.pagination > li {
  display: inline-block;
  margin-left: 1px;
  font-size: 12px;
}
.pagination > li:first-child {
  margin-left: 0;
}

.pagination > li > a,
.pagination > li > span {
  float: none;
  margin-left: 0;
  border: 0;
  display: block;
  color: #333;
  font-weight: 600;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span,
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span,
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span,
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-radius: 2px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #3071a9;
  color: #fff;
}

.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus {
  background-color: #32434D;
  cursor: default;
}

/*ERROR*/
/*
 .acessoNegado {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-image: url("../resources/img/maps.jpg");
    background-repeat:repeat;
  }

.acessoNegado-panel {
	text-align: center;
	width:55%;
	margin: 15% auto 0;
	padding: 60px 40px 80px 50px;
	border-radius:5px;
	color:#ffcd5e;
	background-color:#002638
}

.acessoNegado-panel h1{
	font-size:40px;
	font-weight:bold;
}
*/

.list-group-icon{
	float:left; margin-right:10px;
}

/*JUMBOTRON */
.jumbotron{	border-radius: 4px;}fsdfsdfsdfds


/*HOME*/
.jumbotron {
	padding: 15px;
	background-color:#f2f7f9;
/*	background-image: linear-gradient(to bottom, #3a3a3a, #5a5a5a);*/
	color:#3a3a3a;
}

.welcome .col-md-1{padding-right: 0px;}


@media (max-width: 990px){
.user-img .img-responsive {
	width:25%;
} }

/*
@media (max-width: 990px){
	.col-md-11 h2{font-size:100%}
}*/

.user-img {border:1px solid #BBBBBB;}


/*LOGIN*/

.login {
  color:#fffaaa;
  background-color: #002638;
/*	background: url("../resources/img/panelBackground.png"); */
	width: 45%;
	margin: 5% auto 0;
	padding: 30px 20px 30px 20px;
 	border-radius: 4px;
}



.logobackoffice{
	width: 200px;
}

.logoPrefeitura{
	float:right;
}
.primeiroAcesso {
  float: right;
  text-align: right;
}

.primeiroAcesso a {
  color: #fffaaa;
}

@media (max-width:1024px) {
	
	.login{
		width: 55%;
	}
	
	.logobackoffice{
		width: 80%;
	}

}

@media (max-width:768px) {
	.logobackoffice{
		width: 90%;
	}
	
	.login{
		width: 90%;
	}
	
}


/*
@media (max-width: 640px){
.login {
	width:75%;
} }

*/
/*
 .fullscreen_bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 50%;
/*    background-image: url("../resources/img/bg_taxirio_sistema.jpg");*/
/* 	background-image: url("../resources/img/bg_taxirio_sistema_opacity.jpg");
    background-repeat:repeat;
  }
 */
.login .submitWrap {text-align: right;}

/*.fullscreen_bg h6, .fullscreen_bg_azul h6{color:#fff}*/

.prop {
	padding: 5px;
	display: block;
		
}
.prop .name {
	clear: both;
	font-weight: bold;
	font-size: 14px;
	text-align: left;
	width: 165px;
	float: left; 
}
.prop .value {
	text-align: left;		
}

.prop .required{
	color: red;
	text-align: left;	
}

/*
form {
	padding: 7px 9px 8px 9px;
	font-size: 14px;
	border-color: #ddd;
	box-shadow: none;
	border-radius: 0;
}*/

.ui-widget-content {
    border: 0px solid #a8a8a8 !important;
}


.icon-responsive{
	text-align:center;
}

@media (max-width: 640px){
	.icon-responsive {
		text-align:left;
	} 
	
}

.alert-default{
	color: #333;
    background-color: #f7f7f9;
   	border: 1px solid #e1e1e8;
}
